import {
    ApiReactiveService,
    type ApiServiceUseFetchOptions
} from '@composable-api/service/api.reactive-service'
import type { ApiModel } from '@composable-api/api.model'
import { ProductModel } from '../models/product.model'
import type { ApiServiceFetchOptions } from '@composable-api/service/api.service'

class ProductsApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }

    get(options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'GET',
        })
    }
}

export default function useProductsApiService() {
    return new ProductsApiReactiveService({
        endpoint: '/products',
    }, ProductModel)
}
